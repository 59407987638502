import { DomesticRawData, ESportsCode } from './sports.type'

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ResponseType {
  data?: DataTodoListType
  success?: boolean
}

export interface DataTodoListType {
  cntInfo?: CntInfo[]
  detailInfo?: DetailInfoType[]
  showGameCnt?: string
  curTime?: number
  pageStart?: number
  pageLength?: string
  threeFBonus?: string
  fourFBonus?: string
  fiveFBonus?: string
  sixFBonus?: string
  sevenFBonus?: string
  nineFBonus?: string
  betDelay?: number
  mapAllowMarketLimitIds?: MapAllowMarketLimitIds
  mapStrAllowMarketLimitIds?: MapAllowMarketLimitIds
  mapNotAllowMarketLimitIds?: any
  mapMarketLimitUseYn?: MapAllowMarketLimitIds
}

export interface CntInfo {
  sportsCode?: string
  sportsName?: string
  sportsNameEn?: string
  nationCntList?: NationCntList[]
  count?: number
}

export interface NationCntList {
  nationCode?: string
  nationName?: string
  imgPath?: string
  orderNum?: number
  leagueCntList?: LeagueCntList[]
  count?: number
}

export interface LeagueCntList {
  leagueCode?: string
  leagueName?: string
  imgPath?: string
  family?: string
  no?: number
  count?: string
}

export interface DetailInfoType {
  fixtureId?: number
  sportsCode?: string
  sportsName?: string
  sportsImgPath?: string
  nationName?: string
  leagueCode?: string
  leagueName?: string
  leagueImgPath?: string
  homeTeamName?: string
  awayTeamName?: string
  homeTeamImgPath?: string
  awayTeamImgPath?: string
  startDate?: string
  startDateMod?: string
  gameStatus?: string
  period?: string
  playTime?: string
  homeScore?: number
  awayScore?: number
  homeScores?: string
  awayScores?: string
  homeScoreSub?: number
  awayScoreSub?: number
  group?: number
  esportsType?: number
  rateInfo?: RateInfoType[]
  origRateInfo?: null
  groupCnt?: number
}

export interface RateInfoType {
  fixtureId?: number
  marketId?: number
  marketName?: string
  rateHomeStatus?: string
  rateAwayStatus?: string
  rateDrawStatus?: string
  family?: string | number
  providerName?: string
  lineScore?: string
  hbetCode?: string
  hpickName?: string
  hrate?: number
  hline?: string | number
  hbase?: number
  abetCode?: string
  apickName?: string
  arate?: number
  aline?: string
  abase?: number
  dbetCode?: string
  dpickName?: string
  drate?: number
  dline?: string
  dbase?: number
  bline?: string
}

export interface MapAllowMarketLimitIds {
  [key: string]: string[] | string[][]
}

export interface BetCartType {
  fixture_id?: number
  parent_idx?: string
  bet_code?: string
  select_rate?: string | number
  select_pick_desc?: string
  pick_detail?: string
  market_name?: string
  pick_title?: string
  key?: string
  status?: number
  marketId?: number
  select_idx?: number
  sports_code?: string
  sports_name?: string
  old_rate?: string
}

export interface BetDetailType {
  __typename?: string
  betId?: number
  status?: number
  fixtureId?: number
  selectRate?: number
  baseLine?: string
  startDate?: string
  selectIdx?: string
  selPickName?: string
  homeTeamName?: string
  awayTeamName?: string
  marketName?: string
  gameType?: string
}

export interface BettingSlipType {
  mem_idx?: number
  game_no?: string
  reg_date: string
  result?: number
  betting_cnt?: number
  confirmBetting?: number
  cashBet?: number
  rateBet?: string
  rateBonus?: number
  status?: number
  detailInfos: BetDetailType[]
}

export interface ListCartType {
  __typename?: string
  id?: number
  betDate?: string
  status?: number
  cashBet?: number
  rateBet?: string
  rateBonus?: number
}

export interface ListBetType {
  __typename?: string
  length?: number
  total?: number
  list?: ListCartType[]
  detailList?: BetDetailType[]
  bettingSlips: BettingSlipType[]
}

export interface ResListBetType {
  BetQuery?: ListBetType
}

export type ResponseDataLiveSport = {
  current_page: number
  data: LiveSportItem[]
  first_page_url: string
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

export interface LiveSportItem {
  idx: number
  home_team: string
  home_team_kor?: string
  away_team: string
  away_team_kor?: string
  home_score: number
  away_score: number
  home_scores: any
  away_scores: any
  home_idx: number
  away_idx: number
  sports_idx: number
  league_idx: number
  location_idx: number
  location_name: string
  location_name_kor?: string
  start_date: string
  play_time: string
  status: number
  period: number
  count: number
  league_name: string
  league_name_kor: string
  league: League
  sports_name: string
  main?: DomesticRawData[]
  logo_location: string
  logo_sports: string
  is_start_game: boolean
  period_name?: string
  block_all: boolean
  parents: DomesticRawData[]
  home_team_logo: string | null
  away_team_logo: string | null
  current_period: number
}

export enum EUpdateDomesticType {
  UP = 1,
  DOWN = 2,
  DEFAULT = 3
}

export interface UpdateTypeRate {
  rate1: EUpdateDomesticType
  rate2: EUpdateDomesticType
  rate3: EUpdateDomesticType
}

export interface DomesticWithKeyMatch extends Domestic {
  keyMatch: string
  isChosenFromBoard?: boolean

  // for logic update
  updateType?: UpdateTypeRate
  leagueName: string

  // for pusher
  isShowInBoard?: boolean

  // for logo team
  home_team_logo: string | null
  away_team_logo: string | null

  periodSport?: number
}

export interface LiveSportItemClient extends LiveSportItem {
  domestic: DomesticWithKeyMatch[]
}

export interface Market {
  idx: number
  name: string
  name_en?: string
  group: number
}

export interface League {
  name: string
  idx: number
}

export interface Domestic {
  idx: string
  game_id: string
  sports_name: string
  fixture_idx: number
  sports_idx: number
  league_idx: number
  game_type: number
  game_time: string
  play_time: string
  home_team: string
  away_team: string
  location_name: string
  home_team_sub: string
  vs_team_sub: string
  away_team_sub: string
  rate1: number
  rate2: number
  rate3: number
  result: string
  isStop: string
  betid1: string
  betid2: string
  betid3: string
  isMain: number
  main_line: string
  status: number
  market: Market
  status1: number
  status2: number
  status3: number
}

export interface CntInfoSport {
  sportsCode: number
  sportsName: string
  sportsNameEn: string
  count: number
  imgPath: string
  nationList: NationList[]
}

export interface NationList {
  nationCode: number
  nationName: string
  imgPath: string
  orderNum: number
  count: number
  leagueList: LeagueItem[]
}

export interface LeagueItem {
  leagueCode: number
  leagueName: string
  family: number
  count: number
}

export interface ResponseAllLiveSportDetailInfo {
  detailInfo: ResponseDataLiveSport
  cntInfo: CntInfoSport[]
}

export type ResponseGetAllLiveSports = {
  data: ResponseAllLiveSportDetailInfo

  success: boolean
}

export type LiveCategorySportItem = {
  sportsCode: number
  sportsName: string
  sportsNameEn: string
  count: number
}

export type ResponseGetSportCategories = {
  data: CntInfoSport[]
  success: boolean
}

export type GroupSportListByKey = {
  key: string
  listGroupSport: LiveSportItemClient[]
}

export type ResponseGetRateInfoList = {
  data: Domestic[]
  count: number
  success: boolean
}

export type LimitSport = {
  BettingLimitsSportQuery: {
    max_bet_amount: number
    max_bet_payout: number
    max_payout: number
    min_bet_amount: number
    percent_distribution: number
  }
}

export type TimeWaitingBySports = {
  sports_idx: number
  time: number
}

export type DataTimeWaitingBySports = {
  bet_key: string
  times: TimeWaitingBySports[]
}

export type TimeWaitBettingLiveSportsRes = {
  TimeWaitBettingLiveSportsMutation: DataTimeWaitingBySports
}

export interface ExchangeRateInfoItem {
  sports_code: number
  rate_deduct: number
}

export interface ExchangeRateInfoQuery {
  ExchangeRateInfoQuery: ExchangeRateInfoItem[]
}

export interface BetItemPusher {
  betid: string
  rate: number
  status: number
}

export interface UpdateRecordParent {
  type: string
  status: 'update'
  sports_idx: number
  fixture_idx: number
  bets: BetItemPusher[]
}

export interface UpdateScoreRecord {
  fixture_idx: number
  sports_idx: number
  home_score: number
  away_score: number
  play_time: string // string seconds
  period_name: string
  current_period: number
}

export interface BetItemHistory {
  status: number
  selectRate: number
  startDate: string
  homeTeamName: string
  awayTeamName: string
  homeScore: number
  awayScore: number
  selectedLine: number
  leagueName: string
  betDetail: string
  betResult: string
  teamAndRuleBet: string
  marketName: string
  selectBetTeam: string
  mainLine: string
}

export enum EStatusBetting {
  WAIT,
  LOSE,
  WIN,
  CANCEL,
  SPECIAL = 5
}

export interface BetHistoryGroup {
  _id: string
  betAmount: number
  date: string
  rate: number
  estimateAmount: number
  winAmount: number
  isCancelable: boolean
  settlementStatus: EStatusBetting
  now: number
  betList: BetItemHistory[]
}

export interface BetHistoryData {
  total: number
  per_page: number
  current_page: number
  from: number
  to: number
  last_page: number
  has_more_pages: boolean
  data: BetHistoryGroup[]
  __typename: string
}

export interface BetHistoryQuery {
  BetHistoryQuery: BetHistoryData
}

export type BoardBetSportsGroup = {
  key: number // market group
  group: number
  name: string
  // order: number
  sportsCode: number
  data: DomesticWithKeyMatch[]
}

export type BoardBetSportsItemGroup = {
  order: number
  marketName: string
  gameId: string
  sportsCode: number
  list: DomesticWithKeyMatch[]
}

export const LIST_STATUS_LIVE_MATCH_ALLOW = [2, 3, 9]
export const LIST_PERIOD_LIVE_MATCH_NOT_ALLOW_WHEN_VALID_STATUS = [0, -1, 80]

export const rateKeyList: ['rate1', 'rate2', 'rate3'] = ['rate1', 'rate2', 'rate3']
export const statusKeyList: ['status1', 'status2', 'status3'] = ['status1', 'status2', 'status3']

export const ORDER_MARKET_BY_GAME_ID_OBJ: {
  [key: number | ESportsCode]: {
    [key: number]: string[]
  }
} = {
  [ESportsCode.Basketball]: {
    1: ['226', '342', '28', '221', '220', '51'],
    2: [
      '41',
      '64',
      '21',
      '72',
      '153',
      '155',
      '42',
      '65',
      '45',
      '73',
      '154',
      '156',
      '43',
      '66',
      '46',
      '74',
      '223',
      '222',
      '44',
      '67',
      '47',
      '75',
      '287',
      '288'
    ],
    0: ['282', '53', '77', '62', '354', '355', '390', '284', '283', '337', '285', '1051', '1052']
  },
  [ESportsCode.Football]: {
    1: ['1', '52', '2', '835', '3', '13', '1311', '7', '5', '101', '102', '17'],
    2: ['41', '1694', '64', '21', '72', '456', '113', '42', '45', '73', '211'],
    0: ['4', '16', '56', '6', '198', '199', '409', '11', '30', '31', '79', '214', '938', '939', '143', '144']
  },
  [ESportsCode.IceHockey]: {
    1: ['1', '226', '28', '51', '7', '59'],
    2: ['41', '21', '153', '155', '42', '45', '154', '156', '43', '46', '223', '222'],
    0: []
  },
  [ESportsCode.Volleyball]: {
    1: ['52', '866', '1558', '2', '101', '102', '5', '6'],
    2: ['202', '64', '21', '72', '9', '836', '203', '65', '45', '73'],
    0: ['6']
  },
  [ESportsCode.American_Football]: {
    1: ['226', '342', '28', '7', '51', '221', '220', '69'],
    2: ['202', '64', '21', '72', '456'],
    0: ['390', '282', '53', '77', '25', '62', '70']
  },
  [ESportsCode.Tennis]: {
    1: ['52', '3', '2', '101', '102', '5', '866'],
    2: ['202', '153', '72', '9', '154', '73', '100']
  },
  [ESportsCode.E_Game]: {
    1: ['52', '3'],
    2: ['1149', '989', '669', '1165', '2575', '1251'],
    0: []
  },
  [ESportsCode.Baseball]: {
    1: ['226', '342', '28', '221', '220', '51'],
    2: ['41', '1427', '1561', '2057', '1562', '235', '281', '236'],
    0: ['9']
  },
  [ESportsCode.Handball]: {
    1: ['1', '3', '2', '5', '7', '16'],
    2: ['41', '21', '72', '456', '42', '45', '73', '457'],
    0: ['4']
  },
  [ESportsCode.Boxing]: {
    1: ['52']
  },
  [ESportsCode.Table_Tennis]: {
    1: [],
    2: [],
    0: []
  }
}

export const LIST_COLLAPSE_BY_SPORTS: {
  [key: number | ESportsCode]: string[] | null
} = {
  [ESportsCode.Basketball]: null,
  [ESportsCode.Football]: ['6', '198', '199', '409', '11', '31', '30', '79', '214', '938', '939', '143', '144'],
  [ESportsCode.IceHockey]: null,
  [ESportsCode.Volleyball]: null,
  [ESportsCode.American_Football]: null,
  [ESportsCode.Tennis]: null,
  [ESportsCode.E_Game]: null,
  [ESportsCode.Baseball]: null,
  [ESportsCode.Handball]: ['42', '45'],
  [ESportsCode.Boxing]: null,
  [ESportsCode.Table_Tennis]: null
}

export type FilteredSports = {
  isFavorite: boolean
  isSortBy24h: boolean
  isSortByTomorrow: boolean
  isSortByLeague: boolean
}

export const LIST_CHECK_ONE_SIDE = [ESportsCode.Volleyball]

export const LIST_ONLY_SHOW_ONE_OPTION = []

export const LIST_CHECK_COLLAPSE_OPTION_SPORTS = [ESportsCode.Volleyball, ESportsCode.Football, ESportsCode.Handball]

export const LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS: {
  [key in ESportsCode]: string[] | null
} = {
  [ESportsCode.Football]: [
    '1',
    '52',
    '2',
    '835',
    '3',
    '13',
    '1311',
    '7',
    '5',
    '102',
    '101',
    '17',
    '41',
    '1694',
    '64',
    '21',
    '72',
    '456',
    '113',
    '42',
    '45',
    '73',
    '211',
    '4',
    '16',
    '6',
    '56',
    '199',
    '198',
    '409',
    '11',
    '31',
    '30',
    '79',
    '214',
    '939',
    '938',
    '144',
    '143'
  ],
  [ESportsCode.Basketball]: [
    '226',
    '342',
    '28',
    '221',
    '220',
    '51',
    '41',
    '64',
    '21',
    '72',
    '153',
    '155',
    '42',
    '65',
    '45',
    '73',
    '154',
    '156',
    '43',
    '66',
    '46',
    '74',
    '223',
    '222',
    '44',
    '67',
    '47',
    '75',
    '287',
    '288',
    '282',
    '53',
    '77',
    '62',
    '354',
    '355',
    '390',
    '284',
    '283',
    '337',
    '285',
    '1051',
    '1052'
  ],
  [ESportsCode.Baseball]: [
    '226',
    '342',
    '28',
    '221',
    '220',
    '51',
    '41',
    '1427',
    '1561',
    '2057',
    '1562',
    '235',
    '281',
    '236',
    '9'
  ],
  [ESportsCode.Volleyball]: null,
  [ESportsCode.IceHockey]: [
    '1',
    '226',
    '28',
    '51',
    '7',
    '59',
    '41',
    '21',
    '153',
    '155',
    '42',
    '45',
    '154',
    '156',
    '43',
    '46',
    '223',
    '222'
  ],
  [ESportsCode.Handball]: ['1', '3', '2', '5', '7', '41', '21', '42', '45', '4'],
  [ESportsCode.American_Football]: [
    '226',
    '342',
    '28',
    '7',
    '51',
    '221',
    '220',
    '69',
    '202',
    '64',
    '21',
    '72',
    '456',
    '390',
    '282',
    '53',
    '77',
    '25',
    '62',
    '70'
  ],
  [ESportsCode.Tennis]: null,
  [ESportsCode.E_Game]: ['12', '3', '52', '1149', '989', '669', '1165', '2575', '1251'],
  [ESportsCode.Boxing]: null,
  [ESportsCode.Table_Tennis]: null
}

export const GAME_ID_GROUP_BY_SPORTS: {
  [key in ESportsCode]: { [key: string]: string[] }
} = {
  [ESportsCode.Basketball]: {
    1: ['226', '342', '28', '221', '220', '51'],
    2: [
      '41',
      '64',
      '21',
      '72',
      '153',
      '155',
      '42',
      '65',
      '45',
      '73',
      '154',
      '156',
      '43',
      '66',
      '46',
      '74',
      '223',
      '222',
      '44',
      '67',
      '47',
      '75',
      '287',
      '288'
    ],
    0: ['282', '53', '77', '62', '354', '355', '390', '284', '283', '337', '285', '1051', '1052']
  },
  [ESportsCode.Football]: {
    1: ['1', '52', '2', '835', '3', '13', '1311', '7', '5', '101', '102', '17'],
    2: ['41', '1694', '64', '21', '72', '456', '113', '42', '45', '73', '211'],
    0: ['4', '16', '56', '6', '198', '199', '409', '11', '30', '31', '79', '214', '938', '939', '143', '144']
  },
  [ESportsCode.IceHockey]: {
    1: ['1', '226', '28', '51', '7', '59'],
    2: ['41', '21', '153', '155', '42', '45', '154', '156', '43', '46', '223', '222'],
    0: []
  },
  [ESportsCode.Volleyball]: {
    1: ['52', '866', '1558', '2', '101', '102', '5', '6'],
    2: ['202', '64', '21', '72', '9', '836', '203', '65', '45', '73'],
    0: ['6']
  },
  [ESportsCode.American_Football]: {
    1: ['226', '342', '28', '7', '51', '221', '220', '69'],
    2: ['202', '64', '21', '72', '456'],
    0: ['390', '282', '53', '77', '25', '62', '70']
  },
  [ESportsCode.Tennis]: {
    1: ['52', '3', '2', '101', '102', '5', '866'],
    2: ['202', '153', '72', '9', '154', '73', '100']
  },
  [ESportsCode.E_Game]: {
    1: ['52', '3'],
    2: ['1149', '989', '669', '1165', '2575', '1251'],
    0: []
  },
  [ESportsCode.Baseball]: {
    1: ['226', '342', '28', '221', '220', '51'],
    2: ['41', '1427', '1561', '2057', '1562', '235', '281', '236'],
    0: ['9']
  },
  [ESportsCode.Handball]: {
    1: ['1', '3', '2', '5', '7', '16'],
    2: ['41', '21', '72', '456', '42', '45', '73', '457'],
    0: ['4']
  },
  [ESportsCode.Boxing]: {
    1: ['52']
  },
  [ESportsCode.Table_Tennis]: {
    1: [],
    2: [],
    0: []
  }
}

export const LIST_ID_SHOW_BY_PERIOD: {
  [key in ESportsCode]: { [key: string]: string[] } | null
} = {
  [ESportsCode.Basketball]: {
    '-1': ['226', '342', '28', '221', '220', '51'],
    1: [
      '226',
      '342',
      '28',
      '221',
      '220',
      '51',
      '41',
      '64',
      '21',
      '72',
      '153',
      '155',
      '282',
      '53',
      '77',
      '62',
      '354',
      '355',
      '390'
    ],
    2: [
      '226',
      '342',
      '28',
      '221',
      '220',
      '51',
      '42',
      '65',
      '45',
      '73',
      '154',
      '156',
      '284',
      '283',
      '337',
      '285',
      '1051',
      '1052'
    ],
    3: ['226', '342', '28', '221', '220', '51', '43', '66', '46', '74', '223', '222'],
    4: ['226', '342', '28', '221', '220', '51', '44', '67', '47', '75', '287', '288'],
    40: ['226', '342', '28', '221', '220', '51'],
    80: ['226', '342', '28', '221', '220', '51'],
    100: ['226', '342', '28', '221', '220', '51'],
    101: ['226', '342', '28', '221', '220', '51']
  },
  [ESportsCode.Football]: null,
  // [ESportsCode.IceHockey]: null,
  [ESportsCode.IceHockey]: {
    '-1': ['1', '226', '28', '51', '7', '59'],
    1: ['1', '226', '28', '51', '7', '59', '41', '21', '153', '155'],
    2: ['1', '226', '28', '51', '7', '59', '42', '45', '154', '156'],
    3: ['1', '226', '28', '51', '7', '59', '43', '46', '223', '222'],
    4: ['1', '226', '28', '51', '7', '59'],
    40: ['1', '226', '28', '51', '7', '59'],
    50: ['1', '226', '28', '51', '7', '59'],
    80: ['1', '226', '28', '51', '7', '59'],
    101: ['1', '226', '28', '51', '7', '59'],
    102: ['1', '226', '28', '51', '7', '59']
  },
  [ESportsCode.Volleyball]: null,
  [ESportsCode.American_Football]: null,
  [ESportsCode.Tennis]: null,
  [ESportsCode.E_Game]: null,
  [ESportsCode.Baseball]: null,
  [ESportsCode.Handball]: null,
  [ESportsCode.Boxing]: null,
  [ESportsCode.Table_Tennis]: null
}
