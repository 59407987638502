import { createAction, createReducer } from '@reduxjs/toolkit'
import { DetailMiniGameBetting } from 'src/types/minigame.type'
import { CntInfoSport, MarketInfoItem } from 'src/types/sports.type'

interface CommonState {
  isLoadingPage: boolean
  isPlayingGame: boolean
  isSpinning: boolean
  showCart: boolean
  pageSiteQuery: {
    title?: string
    description?: string
    keywords?: string
    author?: string | null
    is_user_join?: boolean
    is_open_type?: boolean
    captcha?: boolean
    enable_consultation?: boolean
    require_read_note?: boolean
    __typename?: string
  }
  isMaintenance: boolean
  categoriesSports: CntInfoSport[]
  categoriesLiveSports: CntInfoSport[]
  currentCopyText: string
  hiddenAuthModal: boolean
  marketInfoList: MarketInfoItem[]
  currentMiniGame: DetailMiniGameBetting
}

const initialState: CommonState = {
  isLoadingPage: false,
  showCart: false,
  isPlayingGame: false,
  pageSiteQuery: {},
  isMaintenance: false,
  categoriesSports: [],
  categoriesLiveSports: [],
  currentCopyText: '',
  hiddenAuthModal: true,
  marketInfoList: [],
  isSpinning: false,
  currentMiniGame: {} as DetailMiniGameBetting
}

export const setCurrentMiniGame = createAction('setCurrentMiniGame', function (currentMiniGame: DetailMiniGameBetting) {
  return {
    payload: {
      currentMiniGame
    }
  }
})

export const setIsLoadingPage = createAction('loading', function (isLoadingPage: boolean) {
  return {
    payload: {
      isLoadingPage: isLoadingPage
    }
  }
})
export const setIsFetchingGameIframe = createAction(
  'setIsFetchingGameIframe',
  function (isFetchingGameIframe: boolean) {
    return {
      payload: {
        isFetchingGameIframe: isFetchingGameIframe
      }
    }
  }
)

export const setIsSpinning = createAction('setIsSpinning', function (isSpinning: boolean) {
  return {
    payload: {
      isSpinning: isSpinning
    }
  }
})

export const setShowCart = createAction('setShowCart', function (showCart: boolean) {
  return {
    payload: {
      showCart: showCart
    }
  }
})

export const setPageSiteQuery = createAction(
  'setPageSiteQuery',
  function (pageSiteQuery: CommonState['pageSiteQuery']) {
    return {
      payload: {
        pageSiteQuery
      }
    }
  }
)

export const setShowMaintenance = createAction('setShowMaintenance', function (isMaintenance: boolean) {
  return {
    payload: {
      isMaintenance: isMaintenance
    }
  }
})

export const setIsPlayingGame = createAction('setIsPlayingGame', function (isPlayingGame: boolean) {
  return {
    payload: {
      isPlayingGame: isPlayingGame
    }
  }
})

export const setCurrentCopyText = createAction('setCopyText', function (text: string) {
  return {
    payload: {
      currentCopyText: text
    }
  }
})

export const setHiddenAuthModal = createAction('setHiddenAuthModal', function (hidden: boolean) {
  return {
    payload: {
      hiddenAuthModal: hidden
    }
  }
})

export const setCategoriesSports = createAction(
  'setCategoriesSports',
  function ({ categories }: { categories: CntInfoSport[] }) {
    const payload: { categories: CntInfoSport[] } = {
      categories
    }
    return {
      payload
    }
  }
)

export const setCategoriesLiveSports = createAction(
  'setCategoriesLiveSports',
  function ({ categories }: { categories: CntInfoSport[] }) {
    const payload: { categories: CntInfoSport[] } = {
      categories
    }
    return {
      payload
    }
  }
)

export const setMarketInfoList = createAction(
  'setMarketInfoList',
  function ({ marketList }: { marketList: MarketInfoItem[] }) {
    return {
      payload: marketList
    }
  }
)

export const clearCategoriesSports = createAction('clearCategoriesSports')
export const clearMarketInfoList = createAction('clearMarketInfoList')

const commonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIsLoadingPage, (state, action) => {
      state.isLoadingPage = action.payload.isLoadingPage
    })
    .addCase(setShowCart, (state, action) => {
      state.showCart = action.payload.showCart
    })
    .addCase(setPageSiteQuery, (state, action) => {
      state.pageSiteQuery = action.payload.pageSiteQuery
    })
    .addCase(setIsPlayingGame, (state, action) => {
      state.isPlayingGame = action.payload.isPlayingGame
    })
    .addCase(setShowMaintenance, (state, action) => {
      state.isMaintenance = action.payload.isMaintenance
    })
    .addCase(setCategoriesSports, (state, action) => {
      state.categoriesSports = action.payload.categories
    })
    .addCase(setCategoriesLiveSports, (state, action) => {
      state.categoriesLiveSports = action.payload.categories
    })
    .addCase(clearCategoriesSports, (state) => {
      state.categoriesSports = []
    })
    .addCase(setCurrentCopyText, (state, action) => {
      state.currentCopyText = action.payload.currentCopyText
    })
    .addCase(setHiddenAuthModal, (state, action) => {
      state.hiddenAuthModal = action.payload.hiddenAuthModal
    })
    .addCase(setMarketInfoList, (state, action) => {
      state.marketInfoList = action.payload
    })
    .addCase(clearMarketInfoList, (state) => {
      state.marketInfoList = []
    })
    .addCase(setIsSpinning, (state, action) => {
      state.isSpinning = action.payload.isSpinning
    })
    .addCase(setCurrentMiniGame, (state, action) => {
      state.currentMiniGame = action.payload.currentMiniGame
    })
})

export default commonReducer
