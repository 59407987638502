/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ResponseType {
  data?: DataTodoListType
  success?: boolean
}

export enum ESportsCode {
  Football = 6046,
  Basketball = 48242,
  Baseball = 154914,
  Volleyball = 154830,
  IceHockey = 35232,
  Handball = 35709,
  American_Football = 131506,
  Tennis = 54094,
  E_Game = 687890,
  Boxing = 154919,
  Table_Tennis = 265917
}

export interface DataTodoListType {
  cntInfo?: CntInfo[]
  detailInfo?: DetailInfoType[]
  showGameCnt?: string
  curTime?: number
  pageStart?: number
  pageLength?: string
  threeFBonus?: string
  fourFBonus?: string
  fiveFBonus?: string
  sixFBonus?: string
  sevenFBonus?: string
  nineFBonus?: string
  betDelay?: number
  mapAllowMarketLimitIds?: MapAllowMarketLimitIds
  mapStrAllowMarketLimitIds?: MapAllowMarketLimitIds
  mapNotAllowMarketLimitIds?: any
  mapMarketLimitUseYn?: MapAllowMarketLimitIds
}

export interface CntInfo {
  sportsCode?: string
  sportsName?: string
  sportsNameEn?: string
  nationCntList?: NationCntList[]
  count?: number
}

export interface NationCntList {
  nationCode?: string
  nationName?: string
  imgPath?: string
  orderNum?: number
  leagueCntList?: LeagueCntList[]
  count?: number
}

export interface LeagueCntList {
  leagueCode?: string
  leagueName?: string
  imgPath?: string
  family?: string
  no?: number
  count?: string
}

export interface DetailInfoType {
  fixtureId?: number
  sportsCode?: string
  sportsName?: string
  sportsImgPath?: string
  nationName?: string
  leagueCode?: string
  leagueName?: string
  leagueImgPath?: string
  homeTeamName?: string
  awayTeamName?: string
  homeTeamImgPath?: string
  awayTeamImgPath?: string
  startDate?: string
  startDateMod?: string
  gameStatus?: string
  period?: string
  playTime?: string
  homeScore?: number
  awayScore?: number
  homeScores?: string
  awayScores?: string
  homeScoreSub?: number
  awayScoreSub?: number
  group?: number
  esportsType?: number
  rateInfo?: RateInfoType[]
  origRateInfo?: null
  groupCnt?: number
}

export interface RateInfoType {
  fixtureId?: number
  marketId?: number
  marketName?: string
  rateHomeStatus?: string
  rateAwayStatus?: string
  rateDrawStatus?: string
  family?: string | number
  providerName?: string
  lineScore?: string
  hbetCode?: string
  hpickName?: string
  hrate?: number
  hline?: string | number
  hbase?: number
  abetCode?: string
  apickName?: string
  arate?: number
  aline?: string
  abase?: number
  dbetCode?: string
  dpickName?: string
  drate?: number
  dline?: string
  dbase?: number
  bline?: string
}

export interface MapAllowMarketLimitIds {
  [key: string]: string[] | string[][]
}

export interface BetCartType {
  fixture_id?: number
  parent_idx?: string
  bet_code?: string
  select_rate?: string | number
  select_pick_desc?: string
  pick_detail?: string
  market_name?: string
  pick_title?: string
  key?: string
  status?: number
  marketId?: number
  select_idx?: number
  sports_code?: string
  sports_name?: string
  old_rate?: string
}

export interface BetDetailType {
  __typename?: string
  betId?: number
  status?: number
  fixtureId?: number
  selectRate?: number
  baseLine?: string
  startDate?: string
  selectIdx?: string
  selPickName?: string
  homeTeamName?: string
  awayTeamName?: string
  marketName?: string
  gameType?: string
}

export interface BettingSlipType {
  mem_idx?: number
  game_no?: string
  reg_date: string
  result?: number
  betting_cnt?: number
  confirmBetting?: number
  cashBet?: number
  rateBet?: string
  rateBonus?: number
  status?: number
  detailInfos: BetDetailType[]
}

export interface ListCartType {
  __typename?: string
  id?: number
  betDate?: string
  status?: number
  cashBet?: number
  rateBet?: string
  rateBonus?: number
}

export interface ListBetType {
  __typename?: string
  length?: number
  total?: number
  list?: ListCartType[]
  detailList?: BetDetailType[]
  bettingSlips: BettingSlipType[]
}

export interface ListBetHistoryQueryType {
  data?: [ListBetBattleQueryType]
  total?: number
  __typename?: string
  per_page: number
  current_page: number
  to: number
  last_page: number
  has_more_pages: boolean
  from: number
}
export interface ListBetBattleQueryType {
  isCancelledBonusFolder: string
  __typename?: string
  betAmount?: number
  betList?: [ListBetBattleHistoryQueryType]
  date?: string
  estimateAmount?: number
  isCancelable?: boolean
  rate?: number
  settlementStatus?: number
  winAmount?: number
  now?: number
  timeLeftCancelable?: number
  timeLeftDisable?: number
  _id: string
  bonusFolder: number
  bonusFolderRate: number
  enableAutoCancel?: boolean
}

export interface ListBetBattleHistoryQueryType {
  __typename?: string
  status?: number
  selectRate?: number
  startDate?: string
  homeTeamName?: string
  awayTeamName?: string
  marketName?: string
  homeScore?: number
  awayScore?: number
  leagueName?: string
  teamAndRuleBet?: string
  selectedLine?: number
  labelBetStatus?: string
  fixtureStatus?: number
}

export interface ResListBetType {
  BetQuery?: ListBetType
}

export interface SportsBattleType {
  BetHistoryQuery?: ListBetHistoryQueryType
}

export type ResponseDataSport = {
  current_page: number
  data: SportItem[]
  first_page_url: string
  from: number
  last_page: number
  per_page: number
  to: number
  total: number
}

export interface SportItem {
  idx: number
  home_team: string
  home_team_kor?: string
  away_team: string
  away_team_kor?: string
  home_score: number
  away_score: number
  home_scores: any
  away_scores: any
  home_idx: number
  away_idx: number
  sports_idx: number
  league_idx: number
  location_idx: number
  location_name: string
  location_name_kor?: string
  start_date: string
  status: number
  period: number
  count: number
  league_name: string
  league_name_kor?: string
  league: League
  sports_name: string
  domestic: DomesticRawData[]
  logo_location: string
  logo_sports: string
  block_all: boolean
  parents: DomesticRawData[]
  home_team_logo: string | null
  away_team_logo: string | null
}

export enum EUpdateDomesticType {
  UP = 1,
  DOWN = 2,
  DEFAULT = 3
}

export interface UpdateTypeRate {
  rate1: EUpdateDomesticType
  rate2: EUpdateDomesticType
  rate3: EUpdateDomesticType
}

export interface DomesticWithKeyMatch extends Domestic {
  keyMatch: string
  // for logic chosen from board ...
  isChosenFromBoard?: boolean
  isExistingInListDomesticOutSide?: boolean
  // for logic update
  updateType?: UpdateTypeRate
  leagueName: string

  isWinTieLose?: boolean

  // for pusher
  isShowInBoard?: boolean

  home_team_logo: string | null
  away_team_logo: string | null
}

export interface SportItemClient extends SportItem {
  domestic_client: DomesticWithKeyMatch[]
}

export interface Market {
  idx: number
  name: string
  name_en?: string
  group: number
}

export interface League {
  name: string
  idx: number
}

export interface DomesticRawData {
  idx: string
  fixture_idx: number
  sports_idx: number
  game_id: string
  main_line: string | null
  base_line: string | null
  name: string
  rate: number
  status: number
  result: string
  sports_name: string
  league_idx: number
  game_type: number | null
  game_kind: number
  game_time: string
  play_time: string | null
  home_team: string
  away_team: string
  isStop: string
  isMain: string
  market: Market
}

export interface DomesticRawDataGroup {
  key: string
  isShow: boolean
  isOnlyOne: boolean
  data: DomesticRawData[]
}

export interface Domestic {
  idx: string
  game_id: string
  sports_name: string
  fixture_idx: number
  sports_idx: number
  league_idx: number
  game_type: number
  game_time: string
  play_time: string
  home_team: string
  away_team: string
  location_name: string
  home_team_sub: string
  vs_team_sub: string
  away_team_sub: string
  rate1: number
  rate2: number
  rate3: number
  result: string
  isStop: string
  betid1: string
  betid2: string
  betid3: string
  isMain: number
  main_line: string
  status: number
  market: Market
  status1: number
  status2: number
  status3: number
}

export interface CntInfoSport {
  sportsCode: number
  sportsName: string
  sportsNameEn: string
  count: number
  imgPath: string
  nationList: NationList[]
}

export interface NationList {
  nationCode: number
  nationName: string
  imgPath: string
  orderNum: number
  count: number
  leagueList: LeagueItem[]
}

export interface LeagueItem {
  leagueCode: number
  leagueName: string
  family: number
  count: number
}

export interface ResponseAllSportDetailInfo {
  detailInfo: ResponseDataSport
  cntInfo: CntInfoSport[]
}

export type ResponseGetAllSports = {
  data: ResponseAllSportDetailInfo

  success: boolean
}

export type CategorySportItem = {
  sportsCode: number
  sportsName: string
  sportsNameEn: string
  count: number
}

export type ResponseGetSportCategories = {
  data: CntInfoSport[]
  success: boolean
}

export type GroupSportListByKey = {
  key: string
  listGroupSport: SportItemClient[]
}

export type ResponseGetRateInfoList = {
  data: DomesticRawData[]
  count: number
  success: boolean
}

export interface FolderBonusItem {
  bonus_folder_text?: string
  bonus_folder_key?: number
  bonus_folder_rate?: number
  bet_list_length?: number
}

export interface FolderBonusQueryRes {
  folders_bonus: FolderBonusItem[]
  text_folders_bonus: string
  min_rate: number
  is_use_bonus_folders: boolean
}

export interface FolderBonusQuery {
  FolderBonusQuery: FolderBonusQueryRes
}

export interface RuleItem {
  game_id: number
  list_allow: number[]
}

export interface ValidSelectOptionRuleItem {
  sports_code: number
  list_limit: RuleItem[]
  is_can_bet_draw: boolean
}

export interface ValidSelectOptionQuery {
  ValidSelectOptionQuery: ValidSelectOptionRuleItem[]
}

export interface ExchangeRateInfoItem {
  sports_code: number
  rate_deduct: number
}

export interface ExchangeRateInfoQuery {
  ExchangeRateInfoQuery: ExchangeRateInfoItem[]
}

export type LimitSport = {
  BettingLimitsSportQuery: {
    max_bet_amount: number
    max_bet_payout: number
    max_payout: number
    min_bet_amount: number
    percent_distribution: number
  }
}

export interface BetItemPusher {
  betid: string
  rate: number
  status: number
}

export interface UpdateRecordParent {
  type: string
  status: number
  sports_idx: number
  fixture_idx: number
  bets: BetItemPusher[]
}

export interface BetItemHistory {
  status: number
  selectRate: number
  startDate: string
  homeTeamName: string
  awayTeamName: string
  homeScore: number
  awayScore: number
  selectedLine: number
  leagueName: string
  betDetail: string
  betResult: string
  teamAndRuleBet: string
  marketName: string
  selectBetTeam: string
  labelBetStatus: string
  mainLine: string
}

export enum EStatusBetting {
  WAIT,
  LOSE,
  WIN,
  CANCEL,
  SPECIAL = 5
}

export interface BetHistoryGroup {
  _id: string
  betAmount: number
  date: string
  rate: number
  estimateAmount: number
  winAmount: number
  isCancelable: boolean
  settlementStatus: EStatusBetting
  now: number
  betList: BetItemHistory[]
}

export interface BetHistoryData {
  total: number
  per_page: number
  current_page: number
  from: number
  to: number
  last_page: number
  has_more_pages: boolean
  data: BetHistoryGroup[]
  __typename: string
}

export interface BetHistoryQuery {
  BetHistoryQuery: BetHistoryData
}

export const LIST_WIN_TIE_LOSE_ID = ['524', '348', '1', '41', '42', '43', '248', '282', '1561', '235', '409', '284']
export const LIST_WIN_TIE_LOSE_LINE_ID = ['13', '61', '1694']
export const LIST_WIN_LOSE_ID = [
  '226',
  '63',
  '202',
  '2257',
  '2258',
  '1050',
  '2256',
  '52',
  '12',
  '2089',
  '1618',
  '1677',
  '1165',
  '1251',
  '669'
]
export const LIST_FIRST_SCORE_ID = []

export const LIST_WIN_LOSE_ID_ALL = [...LIST_WIN_LOSE_ID, ...LIST_FIRST_SCORE_ID]

export const LIST_UNDER_OVER_HOME_AWAY_OT_ID = ['221', '220']
export const LIST_UNDER_OVER_HALFTIME_PERIOD_ID = ['21', '53', '77', '354', '355', '1427']
export const LIST_UNDER_OVER_ID = [
  '525',
  '1538',
  '1537',
  '352',
  '835',
  '102',
  '2',
  '101',
  '21',
  '155',
  '153',
  '45',
  '156',
  '1852',
  '154',
  '2',
  '46',
  '222',
  '223',
  '1052',
  '1051',
  '469',
  '47',
  '288',
  '287',
  '77',
  '355',
  '354',
  '1857',
  '28',
  '1858',
  '1120',
  '1562',
  '236',
  '2645',
  '2644',
  '166',
  '214',
  '939',
  '938',
  '11',
  '30',
  '31',
  '836',
  '989'
]
export const LIST_UNDER_OVER_ID_ALL = [
  ...LIST_UNDER_OVER_HOME_AWAY_OT_ID,
  ...LIST_UNDER_OVER_HALFTIME_PERIOD_ID,
  ...LIST_UNDER_OVER_ID
]
export const LIST_HANDICAP_ID = [
  '329',
  '752',
  '337',
  '1558',
  '866',
  '526',
  '342',
  '64',
  '65',
  '3',
  '66',
  '283',
  '67',
  '53',
  '2057',
  '281',
  '201',
  '1149'
]
export const LIST_OU_HALFTIME_PERIOD_ID = [
  '21',
  '45',
  '46',
  '47',
  '153',
  '154',
  '223',
  '287',
  '155',
  '156',
  '222',
  '288',
  '77',
  '337',
  '354',
  '1051',
  '355',
  '1052',
  '836',
  '1054'
]

export const LIST_HANDICAP_HALFTIME_PERIOD_ID = ['64', '65', '66', '67', '53', '283', '342']

export const LIST_ODD_EVEN_ID = [
  '51',
  '5',
  '199',
  '198',
  '72',
  '242',
  '243',
  '73',
  '292',
  '289',
  '74',
  '290',
  '75',
  '291',
  '387',
  '388',
  '62',
  '285',
  '2202',
  '73'
]

export const LIST_HALF_ID = ['143', '144']

export const LIST_1X2X12_ID = ['7', '456', '457', '224']
export const LIST_HTFT_ID = ['4', '1312', '390', '70', '2575']
export const LIST_12_AND_UO_ID = ['2733']
export const LIST_SCORE_ID = ['6', '9', '100', '217']
export const LIST_YES_NO_ID = [
  '509',
  '69',
  '1868',
  '1867',
  '123',
  '104',
  '107',
  '96',
  '17',
  '113',
  '211',
  '1850',
  '105',
  '124',
  '97',
  '106',
  '219',
  '1935',
  '192',
  '727',
  '728',
  '729',
  '22',
  '23',
  '1864',
  '1863',
  '1676',
  '79'
]

export const LIST_NO_GOAL_ID = ['59', '56', '92', '55', '16']
export const LIST_SHOW_HOME_AWAY_NAME_ID = ['16']
export const LIST_NEITHER_ID = ['563']

export const LIST_ID_CHECK_RATE = [
  '101',
  '102',
  '153',
  '154',
  '155',
  '156',
  '401',
  '402',
  '558',
  '559',
  '1229',
  '1230',
  '1236',
  '1237',
  '1271',
  '1272',
  '525',
  '1538',
  '1537',
  '220',
  '352',
  '835',
  '2',
  '21',
  '45',
  '1852',
  '46',
  '222',
  '221',
  '1052',
  '1051',
  '469',
  '47',
  '288',
  '287',
  '77',
  '355',
  '354',
  '1857',
  '28',
  '1858',
  '1120',
  '1562',
  '236',
  '2645',
  '2644',
  '329',
  '752',
  '337',
  '1558',
  '866',
  '526',
  '342',
  '64',
  '65',
  '3',
  '66',
  '283',
  '67',
  '53',
  '2057',
  '281',
  '201',
  '214',
  '939',
  '938',
  '11',
  '30',
  '31',
  '143',
  '144',
  '456',
  '409',
  '7',
  '73',
  '836',
  '457',
  '224',
  '4',
  '2257'
]

export const SPORTS_IDX_BASEBALL = 35709

export const MAPPING_CATEGORY_BY_SPORTS_CODE: {
  [key in ESportsCode]: {
    [key: number]: string
  }
} = {
  [ESportsCode.Football]: {
    1: '메인마켓',
    2: '전반전/후반전',
    0: '추가베팅옵션'
  },
  [ESportsCode.Basketball]: {
    1: '메인마켓',
    2: '쿼터별',
    0: '추가베팅옵션'
  },
  [ESportsCode.Baseball]: {
    1: '메인마켓',
    2: '이닝별',
    0: '추가배팅옵션'
  },
  [ESportsCode.Volleyball]: {
    1: '메인마켓',
    2: '세트별',
    0: '추가베팅옵션'
  },
  [ESportsCode.IceHockey]: {
    1: '메인마켓',
    2: '피리어드별',
    0: '추가베팅옵션'
  },
  [ESportsCode.Handball]: {
    1: '메인마켓',
    2: '전반전/후반전',
    0: '추가베팅옵션'
  },
  [ESportsCode.American_Football]: {
    1: '메인마켓',
    2: '전반전/후반전',
    0: '추가배팅옵션'
  },
  [ESportsCode.Tennis]: {
    1: '메인마켓',
    2: '세트별',
    0: '추가베팅옵션'
  },
  [ESportsCode.E_Game]: {
    1: '메인마켓',
    2: '세트별',
    0: '추가베팅옵션'
  },
  [ESportsCode.Boxing]: {
    1: '메인마켓',
    2: '세트별',
    0: '추가베팅옵션'
  },
  [ESportsCode.Table_Tennis]: {
    1: '메인마켓',
    2: '세트별',
    0: '추가베팅옵션'
  }
}

export type BoardBetSportsGroup = {
  key: number // market group
  group: number
  name: string
  // order: number
  sportsCode: number
  data: DomesticWithKeyMatch[]
}

export type BoardBetSportsItemGroup = {
  order: number
  marketName: string
  gameId: string
  sportsCode: number
  list: DomesticWithKeyMatch[]
}

export type MarketTransferItem = {
  sports_idx: number
  market_name: string
  group: 0
}

export type MarketInfoItem = {
  idx: number
  market_transfers: MarketTransferItem[]
  group: number
  name: string
}

export type ResponseGetMarketInfoList = {
  data: MarketInfoItem[]
}

export const rateKeyList: ['rate1', 'rate2', 'rate3'] = ['rate1', 'rate2', 'rate3']
export const statusKeyList: ['status1', 'status2', 'status3'] = ['status1', 'status2', 'status3']

export const SPORT_CODE_SHOW_WINLOSE = [
  ESportsCode.Volleyball,
  ESportsCode.American_Football,
  ESportsCode.Tennis,
  ESportsCode.Baseball,
  ESportsCode.E_Game
]
export const SPORT_CODE_SWAP_WINLOSE_WINTIELOSE = [ESportsCode.Basketball]

export type ConditionRate = {
  min: number
  max: number
}

export const CONDITION_RATE_BASKETBALL: ConditionRate = {
  min: 1.6,
  max: 2.1
}
export const CONDITION_SHOW_IN_BOARD: {
  [key in ESportsCode]: {
    isNeedCheck: boolean
    condition: ConditionRate
  }
} = {
  [ESportsCode.Basketball]: {
    isNeedCheck: true,
    condition: CONDITION_RATE_BASKETBALL
  },
  [ESportsCode.Football]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Baseball]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Volleyball]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.IceHockey]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Handball]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.American_Football]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Tennis]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.E_Game]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Boxing]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  },
  [ESportsCode.Table_Tennis]: {
    isNeedCheck: false,
    condition: { min: -999, max: 999 }
  }
}

const LIST_FIRST_PERIOD = [
  '9',
  '21',
  '41',
  '55',
  '64',
  '72',
  '153',
  '155',
  '194',
  '196',
  '202',
  '242',
  '243',
  '269',
  '317',
  '329',
  '338',
  '343',
  '456',
  '541',
  '596',
  '598',
  '600',
  '634',
  '641',
  '644',
  '647',
  '650',
  '653',
  '663',
  '666',
  '669',
  '672',
  '675',
  '679',
  '682',
  '685',
  '688',
  '692',
  '727',
  '731',
  '735',
  '836',
  '840',
  '841',
  '960',
  '976',
  '982',
  '989',
  '1004',
  '1011',
  '1096',
  '1120',
  '1129',
  '1133',
  '1149',
  '1162',
  '1165',
  '1170',
  '1175',
  '1189',
  '1213',
  '1226',
  '1228',
  '1232',
  '1233',
  '1241',
  '1242',
  '1244',
  '1246',
  '1247',
  '1248',
  '1251',
  '1256',
  '1274',
  '1277',
  '1318',
  '1319',
  '1324',
  '1335',
  '1412',
  '1533',
  '1553',
  '1579',
  '1609',
  '1619',
  '1620',
  '1694',
  '1704',
  '1723',
  '1783',
  '1795',
  '1803',
  '1806',
  '1807',
  '1808',
  '1809',
  '1810',
  '1812',
  '1926',
  '1936',
  '1957',
  '1975',
  '1993',
  '1994',
  '1995',
  '2031',
  '2032',
  '2038',
  '2039',
  '2048',
  '2049',
  '2055',
  '2056',
  '2091',
  '2092',
  '2096',
  '2098',
  '2099',
  '2128',
  '2130',
  '2133',
  '2146',
  '2147',
  '2148',
  '2149',
  '2157',
  '2159',
  '2162',
  '2163',
  '2164',
  '2165',
  '2173',
  '2175',
  '2177',
  '2178',
  '2181',
  '2183',
  '2222',
  '2223',
  '2224',
  '2225',
  '2226',
  '2229',
  '2261',
  '2266',
  '2267',
  '2269',
  '2270',
  '2271',
  '2280',
  '2284',
  '2289',
  '2290',
  '2353',
  '2383',
  '2388',
  '2389',
  '2390',
  '2391',
  '2392',
  '2393',
  '2394',
  '2395',
  '2396',
  '2397',
  '2406',
  '2407',
  '2408',
  '2420',
  '2430',
  '2433',
  '2438',
  '2440',
  '2441',
  '2442',
  '2443',
  '2444',
  '2445',
  '2446',
  '2447',
  '2457',
  '2460',
  '2463',
  '2466',
  '2473',
  '2535',
  '2536',
  '2537',
  '2538',
  '2562',
  '2563',
  '2566',
  '2574',
  '2575',
  '2578',
  '2601',
  '2732'
]

export const LIST_COLLAPSE_OPTION_SPORTS = [
  // PERIOD2
  '42',
  '45',
  '65',
  '73',
  '100',
  '154',
  '156',
  '195',
  '197',
  '203',
  '211',
  '275',
  '289',
  '292',
  '318',
  '330',
  '339',
  '344',
  '457',
  '542',
  '597',
  '599',
  '635',
  '642',
  '645',
  '648',
  '651',
  '654',
  '664',
  '667',
  '670',
  '673',
  '677',
  '680',
  '683',
  '686',
  '689',
  '693',
  '728',
  '732',
  '736',
  '990',
  '1053',
  '1121',
  '1130',
  '1134',
  '1150',
  '1163',
  '1166',
  '1171',
  '1176',
  '1214',
  '1252',
  '1257',
  '1275',
  '1278',
  '1320',
  '1321',
  '1322',
  '1323',
  '1325',
  '1336',
  '1353',
  '1363',
  '1413',
  '1534',
  '1580',
  '1610',
  '1621',
  '1622',
  '1693',
  '1705',
  '1724',
  '1937',
  '1976',
  '2093',
  '2094',
  '2097',
  '2100',
  '2101',
  '2102',
  '2126',
  '2129',
  '2131',
  '2134',
  '2140',
  '2150',
  '2151',
  '2152',
  '2153',
  '2158',
  '2160',
  '2166',
  '2167',
  '2168',
  '2170',
  '2174',
  '2176',
  '2179',
  '2180',
  '2182',
  '2184',
  '2209',
  '2221',
  '2227',
  '2230',
  '2281',
  '2354',
  '2372',
  '2373',
  '2375',
  '2376',
  '2377',
  '2378',
  '2379',
  '2380',
  '2381',
  '2384',
  '2390',
  '2391',
  '2409',
  '2421',
  '2431',
  '2434',
  '2438',
  '2443',
  '2446',
  '2448',
  '2458',
  '2461',
  '2464',
  '2467',
  '2474',
  '2564',
  '2565',
  '2576',
  '2602',
  '2664',
  '469',
  // PERIOD3
  '43',
  '46',
  '66',
  '74',
  '204',
  '217',
  '222',
  '223',
  '224',
  '290',
  '293',
  '319',
  '331',
  '340',
  '345',
  '543',
  '636',
  '643',
  '646',
  '649',
  '652',
  '655',
  '665',
  '668',
  '671',
  '674',
  '678',
  '681',
  '684',
  '687',
  '690',
  '694',
  '729',
  '733',
  '737',
  '878',
  '991',
  '1054',
  '1122',
  '1131',
  '1135',
  '1151',
  '1164',
  '1167',
  '1172',
  '1177',
  '1253',
  '1258',
  '1276',
  '1279',
  '1337',
  '1354',
  '1364',
  '1414',
  '1581',
  '1611',
  '1692',
  '1706',
  '2355',
  '2385',
  '2392',
  '2393',
  '2410',
  '2422',
  '2432',
  '2435',
  '2441',
  '2444',
  '2447',
  '2459',
  '2462',
  '2465',
  '2468',
  '2475',
  '2577',
  '2603',
  '2650',
  '2665',
  // PERIOD4

  '44',
  '47',
  '67',
  '75',
  '205',
  '225',
  '287',
  '288',
  '291',
  '294',
  '320',
  '332',
  '346',
  '394',
  '465',
  '466',
  '467',
  '544',
  '570',
  '730',
  '734',
  '738',
  '1038',
  '1048',
  '1055',
  '1123',
  '1147',
  '1152',
  '1168',
  '1173',
  '1178',
  '1254',
  '1259',
  '1261',
  '1263',
  '1338',
  '1345',
  '1355',
  '1365',
  '1370',
  '1380',
  '1390',
  '1434',
  '1436',
  '1520',
  '1523',
  '1525',
  '1527',
  '1529',
  '1531',
  '1546',
  '1548',
  '1550',
  '1673',
  '1691',
  '1707',
  '1712',
  '1714',
  '2386',
  '2394',
  '2395',
  '2404',
  '2469',
  '2470',
  '2476',
  '2477',
  '2604',
  '2614',
  // PERIOD5
  '48',
  '49',
  '68',
  '76',
  '206',
  '333',
  '347',
  '395',
  '545',
  '571',
  '1039',
  '1049',
  '1124',
  '1148',
  '1153',
  '1169',
  '1174',
  '1179',
  '1255',
  '1260',
  '1262',
  '1264',
  '1339',
  '1346',
  '1356',
  '1366',
  '1371',
  '1381',
  '1391',
  '1435',
  '1437',
  '1521',
  '1524',
  '1526',
  '1528',
  '1530',
  '1532',
  '1547',
  '1549',
  '1551',
  '1555',
  '1556',
  '1674',
  '1713',
  '1715',
  '1731',
  '2387',
  '2396',
  '2397',
  '2605',
  '2615',
  // PERIOD6
  '348',
  '350',
  '352',
  '443',
  '445',
  '447',
  '573',
  '1040',
  '1180',
  '1347',
  '1357',
  '1372',
  '1382',
  '1732',
  '2606',
  '2616',
  // PERIOD7
  '349',
  '351',
  '353',
  '444',
  '446',
  '448',
  '574',
  '1041',
  '1181',
  '1348',
  '1358',
  '1373',
  '1383',
  '1733',
  '2607',
  '2617',
  // PERIOD8
  '434',
  '436',
  '449',
  '451',
  '575',
  '1042',
  '1349',
  '1359',
  '1374',
  '1384',
  '1734',
  '2201',
  '2608',
  '2618',
  // PERIOD9
  '435',
  '437',
  '450',
  '452',
  '576',
  '1043',
  '1350',
  '1360',
  '1375',
  '1385',
  '2202',
  '2609',
  '2619',
  // PERIOD10,
  '566',
  '1341',
  '1394',
  '2610',
  // PERIOD11,
  '567',
  '1342',
  '1395',
  '2611',
  // PERIOD12,
  '568',
  '1343',
  '1396',
  '2612',
  // PERIOD13,
  '569',
  '1344',
  '1397',
  '2613',
  // PERIOD20,
  '1034',
  '1351',
  '1376',
  '2620',
  // PERIOD21,
  '1035',
  '1352',
  '1377',
  '2621',
  // PERIOD22,
  '1036',
  '1353',
  '1378',
  '2622'
]

export const ORDER_MARKET_BY_GAME_ID_OBJ: {
  [key: number | ESportsCode]: {
    [key: number]: string[]
  }
} = {
  [ESportsCode.Basketball]: {
    1: ['1', '226', '342', '28', '16', '2257', '221', '220', '5', '51', '387', '388', '69', '2733'],
    2: [
      '41',
      '64',
      '21',
      '72',
      '153',
      '155',
      '42',
      '65',
      '45',
      '73',
      '154',
      '156',
      '43',
      '66',
      '46',
      '74',
      '223',
      '222',
      '44',
      '67',
      '47',
      '75',
      '287',
      '288'
    ],
    0: ['282', '53', '77', '62', '354', '355', '390', '70']
  },
  [ESportsCode.Football]: {
    1: ['1', '52', '2', '835', '3', '13', '1311', '7', '5', '101', '102', '17'],
    2: ['41', '1694', '64', '21', '72', '456', '113', '42', '45', '73', '211'],
    0: ['4', '16', '56', '6', '198', '199', '409', '11', '30', '31', '79', '214', '938', '939', '143', '144']
  },
  [ESportsCode.IceHockey]: {
    1: ['1', '226', '3', '2', '101', '102', '5', '7', '6'],
    2: [
      '41',
      '64',
      '21',
      '456',
      '9',
      // ...LIST_FIRST_PERIOD,
      '42',
      '65',
      '45',
      '457',
      '100',
      // ...LIST_COLLAPSE_OPTION_SPORTS,
      '43',
      '66',
      '46',
      '224',
      '217'
    ],
    0: ['17', '23', '22', '97', '96']
  },
  [ESportsCode.Volleyball]: {
    1: ['52', '866', '1558', '2', '101', '102', '5', '6'],
    2: [
      '202',
      '64',
      '21',
      '72',
      '9',
      '836',
      ...LIST_FIRST_PERIOD,
      '203',
      '65',
      '45',
      '73',
      ...LIST_COLLAPSE_OPTION_SPORTS
    ],
    0: ['6']
  },
  [ESportsCode.American_Football]: {
    1: ['226', '342', '28', '7', '51', '221', '220', '69'],
    2: ['202', '64', '21', '72', '456'],
    0: ['390', '282', '53', '77', '25', '62', '70']
  },
  [ESportsCode.Tennis]: {
    1: ['52', '3', '2', '101', '102', '5', '866'],
    2: ['202', '153', '72', '9', ...LIST_FIRST_PERIOD, '154', '73', '100', ...LIST_COLLAPSE_OPTION_SPORTS]
  },
  [ESportsCode.E_Game]: {
    1: ['52', '3'],
    2: ['1149', '989', '669', '1165', '2575', '1251'],
    0: []
  },
  [ESportsCode.Baseball]: {
    1: ['226', '342', '28', '221', '220', '51'],
    2: ['41', '1427', '1561', '2057', '1562', '235', '281', '236'],
    0: ['9']
  },
  [ESportsCode.Handball]: {
    1: ['1', '3', '2', '5', '7', '16'],
    2: ['41', '21', '72', '456', '42', '45', '73', '457'],
    0: ['4']
  },
  [ESportsCode.Boxing]: {
    1: ['52']
  },
  [ESportsCode.Table_Tennis]: {
    1: [],
    2: [],
    0: []
  }
}

export const LIST_COLLAPSE_BY_SPORTS: {
  [key: number | ESportsCode]: string[] | null
} = {
  [ESportsCode.Basketball]: null,
  [ESportsCode.Football]: ['6', '198', '199', '409', '11', '31', '30', '79', '214', '938', '939', '143', '144'],
  [ESportsCode.IceHockey]: null,
  [ESportsCode.Volleyball]: null,
  [ESportsCode.American_Football]: null,
  [ESportsCode.Tennis]: null,
  [ESportsCode.E_Game]: null,
  [ESportsCode.Baseball]: null,
  [ESportsCode.Handball]: ['42', '45'],
  [ESportsCode.Boxing]: null,
  [ESportsCode.Table_Tennis]: null
}

export type FilteredSports = {
  isFavorite: boolean
  isSortBy24h: boolean
  isSortByTomorrow: boolean
  isSortByLeague: boolean
}

export const LIST_CHECK_ONE_SIDE = [ESportsCode.Volleyball]

export const LIST_ONLY_SHOW_ONE_OPTION = []

export const LIST_CHECK_COLLAPSE_OPTION_SPORTS = [ESportsCode.Volleyball, ESportsCode.Football, ESportsCode.Handball]

export const LIST_GAME_ID_SHOW_IN_BOARD_BY_SPORTS: {
  [key in ESportsCode]: string[] | null
} = {
  [ESportsCode.Football]: [
    '1',
    '52',
    '2',
    '835',
    '3',
    '13',
    '1311',
    '7',
    '5',
    '102',
    '101',
    '17',
    '41',
    '1694',
    '64',
    '21',
    '72',
    '456',
    '113',
    '42',
    '45',
    '73',
    '211',
    '4',
    '16',
    '6',
    '56',
    '199',
    '198',
    '409',
    '11',
    '31',
    '30',
    '79',
    '214',
    '939',
    '938',
    '144',
    '143'
  ],
  [ESportsCode.Basketball]: [
    '1',
    '226',
    '342',
    '28',
    '221',
    '220',
    '5',
    '51',
    '387',
    '388',
    '69',
    '41',
    '64',
    '21',
    '72',
    '153',
    '155',
    '42',
    '65',
    '45',
    '73',
    '154',
    '156',
    '43',
    '66',
    '46',
    '74',
    '223',
    '222',
    '44',
    '67',
    '47',
    '75',
    '287',
    '288',
    '282',
    '53',
    '77',
    '62',
    '354',
    '355',
    '50',
    '70',
    '2733',
    '390',
    '16',
    '2257'
  ],
  [ESportsCode.Baseball]: [
    '226',
    '342',
    '28',
    '221',
    '220',
    '51',
    '41',
    '1427',
    '1561',
    '2057',
    '1562',
    '235',
    '281',
    '236',
    '9'
  ],
  [ESportsCode.Volleyball]: null,
  [ESportsCode.IceHockey]: [
    '1',
    '226',
    '3',
    '2',
    '101',
    '102',
    '5',
    '7',
    '6',
    '41',
    '64',
    '21',
    '456',
    '9',
    '42',
    '65',
    '45',
    '457',
    '100',
    '43',
    '66',
    '46',
    '224',
    '217',
    '17',
    '23',
    '22',
    '97',
    '96'
  ],
  [ESportsCode.Handball]: ['1', '3', '2', '5', '7', '41', '21', '42', '45', '4'],
  [ESportsCode.American_Football]: [
    '226',
    '342',
    '28',
    '7',
    '51',
    '221',
    '220',
    '69',
    '202',
    '64',
    '21',
    '72',
    '456',
    '390',
    '282',
    '53',
    '77',
    '25',
    '62',
    '70'
  ],
  [ESportsCode.Tennis]: null,
  [ESportsCode.E_Game]: ['12', '3', '52', '1149', '989', '669', '1165', '2575', '1251'],
  [ESportsCode.Boxing]: null,
  [ESportsCode.Table_Tennis]: null
}

export const LENGTH_GROUP_BY_GAME_ID = {
  1: null,
  2: [
    ...LIST_HANDICAP_ID,
    ...LIST_UNDER_OVER_ID_ALL,
    ...LIST_WIN_LOSE_ID_ALL,
    ...LIST_HALF_ID,
    ...LIST_NEITHER_ID,
    ...LIST_YES_NO_ID,
    ...LIST_ODD_EVEN_ID
  ],
  3: [
    ...LIST_WIN_TIE_LOSE_ID,
    ...LIST_WIN_TIE_LOSE_LINE_ID,
    ...LIST_1X2X12_ID,
    ...LIST_HALF_ID,
    ...LIST_NEITHER_ID,
    ...LIST_NO_GOAL_ID
  ]
}

export const CONDITION_BY_SPORTS: {
  [key in ESportsCode]: {
    [key: string]: {
      isMaxMainLine: boolean
      conditionList: ConditionRate[]
    }
  }
} = {
  [ESportsCode.Football]: {
    // 1: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    3: {
      isMaxMainLine: false,
      conditionList: [
        { min: 1.7, max: 2.1 },
        { min: 1.6, max: 2.3 },
        { min: 1.4, max: 2.9 }
      ]
    },
    // under/over
    2: {
      isMaxMainLine: true,
      conditionList: [
        { min: 1.65, max: 2.2 },
        { min: 1.55, max: 2.4 },
        { min: 1.4, max: 2.75 }
      ]
    }
  },
  [ESportsCode.Basketball]: {
    // 226: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    342: {
      isMaxMainLine: false,
      conditionList: [
        { min: 1.85, max: 1.95 },
        { min: 1.8, max: 2.1 },
        { min: 1.6, max: 2.4 }
      ]
    },
    // under/over
    28: {
      isMaxMainLine: true,
      conditionList: [
        { min: 1.85, max: 1.95 },
        { min: 1.8, max: 2.1 },
        { min: 1.6, max: 2.4 }
      ]
    }
  },
  [ESportsCode.Baseball]: {
    // 226: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    342: {
      isMaxMainLine: false,
      conditionList: [
        { min: 1.85, max: 1.95 },
        { min: 1.8, max: 2.1 },
        { min: 1.6, max: 2.4 }
      ]
    },
    // under/over
    28: {
      isMaxMainLine: true,
      conditionList: [
        { min: 1.85, max: 1.95 },
        { min: 1.8, max: 2.1 },
        { min: 1.6, max: 2.4 }
      ]
    }
  },
  [ESportsCode.Volleyball]: {
    // 52: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    866: {
      isMaxMainLine: false,
      conditionList: [
        { min: 1.7, max: 2.2 },
        { min: 1.6, max: 2.3 },
        { min: 1.4, max: 2.6 }
      ]
    },
    // under/over
    2: {
      isMaxMainLine: true,
      conditionList: [{ min: 1.6, max: 1.99 }]
    }
  },
  [ESportsCode.IceHockey]: {
    // 1: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    3: {
      isMaxMainLine: false,
      conditionList: [
        { min: 1.7, max: 2.1 },
        { min: 1.6, max: 2.3 },
        { min: 1.4, max: 2.9 }
      ]
    },
    // under/over
    2: {
      isMaxMainLine: true,
      conditionList: [
        { min: 1.8, max: 1.99 },
        { min: 1.7, max: 2.2 },
        { min: 1.5, max: 2.6 }
      ]
    }
  },
  [ESportsCode.Handball]: {
    // 1: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    3: {
      isMaxMainLine: false,
      conditionList: [{ min: 1, max: 99 }]
    },
    // under/over
    2: {
      isMaxMainLine: false,
      conditionList: [{ min: 1, max: 99 }]
    }
  },
  [ESportsCode.American_Football]: {
    // 1: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    3: {
      isMaxMainLine: false,
      conditionList: [{ min: 1, max: 99 }]
    },
    // under/over
    2: {
      isMaxMainLine: false,
      conditionList: [{ min: 1, max: 99 }]
    }
  },
  [ESportsCode.Tennis]: {
    // 52: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    201: {
      isMaxMainLine: false,
      conditionList: [{ min: 1.75, max: 1.95 }]
    },
    // under/over
    166: {
      isMaxMainLine: true,
      conditionList: [{ min: 1.8, max: 2.1 }]
    }
  },
  [ESportsCode.E_Game]: {
    // 52: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // },
    // handicap
    3: {
      isMaxMainLine: false,
      conditionList: [{ min: 1, max: 99 }]
    },
    // under/over
    29: {
      isMaxMainLine: false,
      conditionList: [{ min: 1, max: 99 }]
    }
  },
  [ESportsCode.Boxing]: {
    // 52: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // }
  },
  [ESportsCode.Table_Tennis]: {
    // 52: {
    //   isMaxMainLine: false,
    //   conditionList: [{ min: 1, max: 99 }]
    // }
  }
}
