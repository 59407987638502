import { BetMiniGameRequest, OptionMiniGameItem } from 'src/types/minigame.type'
import { BetMiniGameItem } from '../stores/minigame.reducer'

export const transformCartMiniGameToPickList = ({
  betItem,
  betAmount,
  gameType
}: {
  betItem: BetMiniGameItem
  betAmount: number
  gameType: string
}): BetMiniGameRequest => {
  return {
    betAmount,
    transId: betItem.miniGameItem.transId,
    details: `${betItem.miniGameItem.text} (${betItem.miniGameItem.category})`,
    game: gameType,
    rate: betItem.miniGameItem.rate,
    selectedItem: betItem.selectedKeyItem
  }
}

export const groupDataOptionsByTab = (listOption: OptionMiniGameItem[]): Map<string, OptionMiniGameItem[]> | null => {
  if (!listOption?.length || listOption[0]?.tab === null) {
    return null
  }

  const groupByTab = new Map<string, OptionMiniGameItem[]>()

  listOption.forEach((option) => {
    if (option.tab !== null) {
      // const key = `${option.tab}|${option.tab_text}`
      const key = `${option.tab_text}`
      if (groupByTab.has(key)) {
        const old = groupByTab.get(key)
        groupByTab.set(key, [...(old || []), option])
      } else {
        groupByTab.set(key, [option])
      }
    }
  })

  return groupByTab
}

export const groupDataOptionsByCategory = (
  listOption: OptionMiniGameItem[]
): Map<string, OptionMiniGameItem[]> | null => {
  if (!listOption?.length || listOption[0]?.category === null) {
    return null
  }

  const groupByCategory = new Map<string, OptionMiniGameItem[]>()
  listOption.forEach((option) => {
    if (option.category !== null) {
      // const key = `${option.category}|${option.category_text}`
      const key = `${option.category_text}`
      if (groupByCategory.has(key)) {
        const old = groupByCategory.get(key)
        groupByCategory.set(key, [...(old || []), option])
      } else {
        groupByCategory.set(key, [option])
      }
    }
  })

  return groupByCategory
}

export function convertMapToArrayMiniGame<T>(map: Map<string, T[]>): { key: string; value: T[] }[] {
  const array: { key: string; value: T[] }[] = []

  map.forEach((value, key) => {
    array.push({
      key: key,
      value: value
    })
  })
  return array
}

export function formatTimeFromSeconds(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}
