import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilteredSports } from 'src/libs/stores/sport-search.reducer'
import { RootState } from 'src/libs/utils/store'
import { FilteredSports } from 'src/types/sports.type'

export const useSportSearchStore = () => {
  const dispatch = useDispatch()
  const {
    eventId,
    dataRateInfoList,
    dataSportSearch,
    dataSportSearchPure,
    isLoadingRateInfo,
    isLoadingSports,
    isOpenBoardBetSport,
    isFirstTimeFetch,
    rules,
    exchangeRateInfoList,
    filteredSports,
    dataRateInfoListPure
  } = useSelector((state: RootState) => state.sportSearch)

  function getRulesBySportsIdx(sportIdx: number) {
    return rules.find((rule) => rule.sports_code === sportIdx) || null
  }

  const objExchangeRateInfo = useMemo(() => {
    return exchangeRateInfoList.reduce<{ [key: string | number]: number }>((acc, cur) => {
      if (!acc[cur.sports_code]) {
        acc[cur.sports_code] = cur.rate_deduct
      }
      return acc
    }, {})
  }, [exchangeRateInfoList])

  const sportData = useMemo(() => {
    return dataSportSearchPure.find((item) => item.idx === +eventId)
  }, [dataSportSearchPure, eventId])

  function handleChangeFilteredSports({
    isFavorite,
    isSortBy24h,
    isSortByTomorrow,
    isSortByLeague
  }: Partial<FilteredSports>) {
    dispatch(
      setFilteredSports({
        isFavorite: isFavorite || false,
        isSortBy24h: isSortBy24h || false,
        isSortByTomorrow: isSortByTomorrow || false,
        isSortByLeague: isSortByLeague || false
      })
    )
  }

  return {
    eventId,
    dataRateInfoList,
    dataSportSearch,
    dataSportSearchPure,
    isLoadingSports,
    isLoadingRateInfo,
    isOpenBoardBetSport,
    isFirstTimeFetch,
    rules,
    getRulesBySportsIdx,
    objExchangeRateInfo,
    sportData,
    filteredSports,
    handleChangeFilteredSports,
    dataRateInfoListPure
  }
}
