/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLazyQuery, useQuery } from '@apollo/client'
import { Modal, Spin } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SearchGameInput from 'src/components/SearchGameInput'
import path from 'src/constants/path'
import {
  GET_DATA_GAME,
  GET_DATA_GAME_SLOT,
  GET_PROVIDER,
  GET_PROVIDERS
} from 'src/libs/apis/graphql/queries/game.query'
import { SiteInfoQuery } from 'src/libs/apis/graphql/queries/siteInfo.query'
import useQueryParams from 'src/libs/hooks/useQueryParams'
import { setIsLoadingPage, setIsPlayingGame,setIsFetchingGameIframe } from 'src/libs/stores/common.reducer'
import { isDev } from 'src/libs/utils/env'
import { formatQueryParams, isWithinMaintenancePeriod } from 'src/libs/utils/utilFuncs'
import { SiteInfoQueryType } from 'src/types/common.type'
import { Provider, ProviderDetailType } from 'src/types/game.type'

// const CATEGORY_SLOT = 'Slot'
const TYPE_CHOOSE_SLOT = 'Slot, Slot + Live Casino'
const DEFAULT_LIMIT = 12
const DEFAULT_OFFSET = 1

export default function Slot({ isHome }: { isHome?: boolean }) {
  const [firstLoading, setFirstLoading] = useState(false)
  const [querySlot, setQuerySlot] = useState({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET, total: 0 })
  const [listProvider, setListProvider] = useState<Provider[]>()
  const [listProviderData, setListProviderData] = useState<ProviderDetailType[]>()
  const [showGame, setShowGame] = useState(false)
  const [gCodeSlot, setGCodeSlot] = useState('')
  const [urlGame, setUrlGame] = useState('')

  const queryParams = useQueryParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const buttonRefSlot = useRef<HTMLButtonElement>(null)

  /* Query data for Slot */
  const { data: dataProviderSlotDefault, loading } = useQuery(GET_PROVIDERS, {
    variables: {
      limit: querySlot?.limit || DEFAULT_LIMIT,
      offset: querySlot?.offset || DEFAULT_OFFSET,
      categories: TYPE_CHOOSE_SLOT
    },
    context: { apiName: 'game-provider' },
    skip: !!queryParams.proCode,
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })

  const { data: dataProvider, refetch: refetchProvider } = useQuery(GET_PROVIDERS, {
    variables: {
      limit: querySlot?.limit || DEFAULT_LIMIT,
      offset: querySlot?.offset || DEFAULT_OFFSET,
      categories: TYPE_CHOOSE_SLOT,
      game_providers: `${queryParams?.[`filter${TYPE_CHOOSE_SLOT}`] ? queryParams?.[`filter${TYPE_CHOOSE_SLOT}`] : ''}`,
      search:
        queryParams?.[`search${TYPE_CHOOSE_SLOT}`] &&
        queryParams?.[`search${TYPE_CHOOSE_SLOT}`]?.toLowerCase() !== 'slot'
          ? queryParams?.[`search${TYPE_CHOOSE_SLOT}`]
          : ''
    },
    context: { apiName: 'game-provider' },
    skip: !!queryParams.proCode,
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })

  const { data: dataListProvider, refetch: refetchListProvider } = useQuery(GET_PROVIDER, {
    variables: {
      limit: querySlot?.limit || DEFAULT_LIMIT,
      offset: querySlot?.offset || DEFAULT_OFFSET,
      provider_code: queryParams.proCode ?? '',
      category: 'slot',
      search:
        queryParams?.[`search${TYPE_CHOOSE_SLOT}`] &&
        queryParams?.[`search${TYPE_CHOOSE_SLOT}`]?.toLowerCase() !== 'slot'
          ? queryParams?.[`search${TYPE_CHOOSE_SLOT}`]
          : ''
    },
    context: { apiName: 'game-provider' },
    refetchWritePolicy: 'merge',
    skip: !queryParams.proCode,
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })
  const [queryGameSlot, { data: dataGameSlot, loading: loadingGameSlot, refetch: refetchGameSlot }] = useLazyQuery(
    GET_DATA_GAME,
    {
      context: { apiName: 'game' },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )
  const [getGameProvider] = useLazyQuery(GET_PROVIDER, {
    variables: {
      limit: querySlot?.limit || DEFAULT_LIMIT,
      offset: querySlot?.offset || DEFAULT_OFFSET,
      provider_code: queryParams.proCode ?? '',
      category: 'slot',
      search:
        queryParams?.[`search${TYPE_CHOOSE_SLOT}`] &&
        queryParams?.[`search${TYPE_CHOOSE_SLOT}`]?.toLowerCase() !== 'slot'
          ? queryParams?.[`search${TYPE_CHOOSE_SLOT}`]
          : ''
    },
    fetchPolicy: 'no-cache',
    context: { apiName: 'game-provider' },
    refetchWritePolicy: 'merge'
  })

  const {
    data: dataGameDetailSlot,
    loading: loadingGameDetailSlot,
    refetch: refetchGame
  } = useQuery(GET_DATA_GAME_SLOT, {
    variables: { provider_code: queryParams.proCode, game_code: gCodeSlot },
    context: { apiName: 'game' },
    skip: !queryParams.proCode || !gCodeSlot,
    onError: (error) => {
      toast.error(error?.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
    }
  })

  const [getSlotGame] = useLazyQuery(GET_DATA_GAME_SLOT, {
    context: { apiName: 'game' },
    fetchPolicy: 'no-cache'
  })
  /* End Query for Slot */

  const chooseGame = async (gpCode: string): Promise<void> => {
    if (!queryParams.proCode) {
      return
    }
    dispatch(setIsFetchingGameIframe(false))

    
    const { data, error } = await getSlotGame({
      variables: {
        provider_code: queryParams.proCode,
        game_code: gpCode
      }
    })
    dispatch(setIsFetchingGameIframe(true))

    if (error) {
      toast.error(error.message)
      setUrlGame('')
      setGCodeSlot('')
      return
    }

    if (window.innerWidth < 768) {
      window.open(data?.GameQuery?.url, '_blank')
      return
    }

    setShowGame(true)
    dispatch(setIsPlayingGame(true))
    setUrlGame(data?.GameQuery?.url || '')
    setGCodeSlot('')
  }

  const chooseGameSlot = async (gpCode: string): Promise<void> => {
    const { data, error } = await queryGameSlot({
      variables: { provider_code: gpCode || '' }
    })

    if (error) {
      toast.error(error.message)
      return
    }

    if (window.innerWidth < 768) {
      window.open(data?.GameQuery?.url, '_blank')
      return
    }

    setShowGame(true)
    dispatch(setIsPlayingGame(true))
    setUrlGame(data?.GameQuery?.url || '')
    setGCodeSlot('')
  }

  const chooseProviderSlot = async (gpCode: string) => {
    const { data, error } = await getGameProvider({
      variables: {
        limit: querySlot?.limit || DEFAULT_LIMIT,
        offset: querySlot?.offset || DEFAULT_OFFSET,
        provider_code: gpCode,
        category: 'slot',
        search: ''
      }
    })
    if (error) {
      toast.error(error.message || '문제가 발생했습니다. 나중에 다시 시도 해주십시오.')
      return
    }
    delete queryParams.limitSlot
    delete queryParams.offsetSlot
    setFirstLoading(false)
    setListProvider([])
    setQuerySlot({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET, total: 0 })
    delete queryParams?.[`search${TYPE_CHOOSE_SLOT}`]
    const newQueryParams = { ...queryParams, proCode: gpCode }
    navigate(location.pathname + formatQueryParams(newQueryParams))
  }

  useEffect(() => {
    if (queryParams?.[`filter${TYPE_CHOOSE_SLOT}`] || queryParams?.[`search${TYPE_CHOOSE_SLOT}`]) refetchProvider()
  }, [queryParams, queryParams.filter, queryParams.search, refetchProvider])

  useEffect(() => {
    if (!firstLoading && (loading || loadingGameDetailSlot)) {
      dispatch(setIsLoadingPage(true))
      setFirstLoading(true)
    }

    return () => {
      dispatch(setIsLoadingPage(false))
    }
  }, [dispatch, loading, loadingGameDetailSlot])

  const loadMoreDataSlot = useCallback(() => {
    setQuerySlot({ ...querySlot, limit: querySlot.limit + 12 })

    if (queryParams.proCode) {
      refetchListProvider()
    } else {
      refetchProvider()
    }
  }, [queryParams?.proCode, querySlot, dataProvider])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) loadMoreDataSlot()
      })
    })

    if (buttonRefSlot.current) observer.observe(buttonRefSlot.current)

    return () => {
      if (buttonRefSlot.current) observer.unobserve(buttonRefSlot.current)
    }
  }, [loadMoreDataSlot, queryParams?.proCode, dataListProvider])

  useEffect(() => {
    if (dataProvider || dataProvider?.GameProviders?.data || dataProvider?.GameProvider?.data?.length > 0) {
      setListProvider(dataProvider?.GameProviders?.data)
    }
  }, [dataProvider])

  useEffect(() => {
    if (
      dataListProvider &&
      dataListProvider?.GameProvider &&
      dataListProvider?.GameProvider?.data?.length > 0 &&
      queryParams.proCode
    ) {
      setListProviderData(dataListProvider?.GameProvider?.data)
    }

    if (dataListProvider && dataListProvider?.GameProvider && dataListProvider?.GameProvider?.data?.length === 0) {
      setListProviderData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListProvider, queryParams.proCode, queryParams?.[`search${TYPE_CHOOSE_SLOT}`]])

  useEffect(() => {
    if (!queryParams.proCode) setListProviderData([])
  }, [queryParams.proCode])

  useEffect(() => {
    if (isHome) return

    window.scrollTo(0, 0)
  }, [isHome])

  const { data: dataSiteInfo } = useQuery<SiteInfoQueryType>(SiteInfoQuery, {
    context: { apiName: 'siteInfo' },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!dataSiteInfo) return
    // nếu siEnableGamesConfig là true thì kiểm tra nếu ở casino/slot thì maintain
    // nếu siEnableGamesConfig là false thì kiểm tra nếu config của riêng casino/slot là true thì maintain
    if (
      (dataSiteInfo?.SiteInfoQuery?.game_config?.siEnableGamesConfig &&
        (location.pathname === path.casino || location.pathname === path.slot)) ||
      (!dataSiteInfo?.SiteInfoQuery?.game_config?.siEnableGamesConfig &&
        ((dataSiteInfo?.SiteInfoQuery?.game_config?.casino?.enable && location.pathname === path.casino) ||
          (dataSiteInfo?.SiteInfoQuery?.game_config?.slot?.enable && location.pathname === path.slot)))
    ) {
      navigate(path.maintain, { state: dataSiteInfo?.SiteInfoQuery?.game_config?.siEnableGamesConfigNotice })
    }
  }, [dataSiteInfo, location.pathname])

  return (
    <div
      className={classNames('mt-2 px-4 pb-14', {
        [' lg:max-h-[calc(100dvh-150px)] overflow-y-auto sidebar__overflow']: !isHome
      })}
      data-aos={isHome ? '' : 'fade-up'}
    >
      {!isHome && (
        <div className='aspect-[1300/355]'>
          <img src='/assets/images/slot/banner-yellow15-black.png' className='w-full h-full object-cover' alt='' />
        </div>
      )}
      {(queryParams.tab === 'slot' || queryParams.tab === 'main' || !queryParams.tab) && (
        <>
          {/* SLOT */}
          <div className='bg-[#0b0d10] p-4 rounded-md font-semibold my-4 flex  items-center flex-wrap'>
            {/* <div className='flex items-center gap-2'>
          <img className='mr-[16px] w-[50px] h-[40px]' src='/assets/images/casino/slot-game.png' alt='' />
          <p className='text-content'>
            <span className='text-white'>슬롯 게임 </span>
            공정성이 보장되는 1000여가지 메이저 슬롯들이 인기순으로 정렬{' '}
          </p>
        </div> */}
            <p className='text-white flex-auto mb-2 lg:mb-0'>
              <span className='text-yellow-1'>슬롯 게임 </span>
            </p>
            <div className='filter'>
              <SearchGameInput
                providers={dataProviderSlotDefault?.GameProviders?.data}
                defaultValue={queryParams?.[`search${TYPE_CHOOSE_SLOT}`]}
                refetch={refetchProvider}
                typeParams={TYPE_CHOOSE_SLOT}
                defaultValueFilter={queryParams?.[`filter${TYPE_CHOOSE_SLOT}`]}
              />
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 md:grid-cols-4'>
            {listProvider?.map((provider: Provider, index: number) => {
              const isMaintain = provider?.gpMaintenance && JSON.parse(provider?.gpMaintenance)
              const gpImgBackground = isDev
                ? '/bg_slot_provider_default.png'
                : (provider?.gpImgBackground && JSON.parse(provider?.gpImgBackground)?.slot) || ''
              const gpImage = (provider?.gpImage && JSON.parse(provider?.gpImage)?.slot) || ''
              const gpLogo = (provider?.gpLogo && JSON.parse(provider?.gpLogo)?.slot) || ''

              let isDisabled = false
              if (isMaintain?.slot?.time) {
                isDisabled = isWithinMaintenancePeriod(isMaintain?.slot)
              }

              return (
                <button
                  onClick={() => {
                    if (isDisabled) return
                    if (provider?.gpCode === 'Booongo' || provider?.gpCode === 'Playson') {
                      chooseGameSlot(provider?.gpCode ?? '')
                    } else {
                      chooseProviderSlot(provider?.gpCode ?? '')
                    }
                  }}
                  key={index}
                  className='flex flex-col gap-2 group'
                >
                  <div className='w-full overflow-hidden duration-150 relative border border-primary group-hover:border-primary aspect-[1.64]'>
                    <div className='relative w-full h-full overflow-hidden'>
                      <img
                        src={gpImgBackground}
                        alt=''
                        // className='w-full h-full group-hover:blur-sm'
                        className='w-full h-full block'
                        onError={(e) => {
                          e.currentTarget.src = '/default-bg-provider.png'
                          e.currentTarget.onerror = null
                        }}
                      />
                      <div className='absolute inset-0 flex justify-center'>
                        <img
                          src={gpImage}
                          alt={provider?.gpName}
                          title={provider?.gpName}
                          className='max-w-[35%] h-auto object-contain'
                          onError={(e) => {
                            e.currentTarget.src = '/default_slot_character.png'
                            e.currentTarget.onerror = null
                          }}
                        />
                      </div>
                      {/* <div className='absolute bottom-0 left-0 w-full h-full duration-150 flex flex-col justify-end items-center'>
                    <img
                      src={gpImage}
                      alt={provider?.gpName}
                      title={provider?.gpName}
                      width='310'
                      height='282'
                      className='product__image w-fit duration-150 transform-ani-child'
                      onError={(e) => {
                        e.currentTarget.src = '/casino_default.png'
                        e.currentTarget.onerror = null
                      }}
                    />
                  </div> */}
                      <div className='w-full h-full z-[2] delay-200 duration-200 absolute left-0 top-2 flex flex-col justify-start'>
                        <div className='duration-200 z-[1]'>
                          <img src={gpLogo} alt='' className='w-3/4 md:w-fit max-w-[160px] max-h-[60px]' />
                        </div>
                      </div>

                      {isDisabled && (
                        <div
                          className='absolute top-0 left-0 w-full h-full z-[3] pointer-events-none'
                          style={{
                            background: `rgba(0,0,0,0.8) url(/assets/images/casino/main-taincheck.png) center center no-repeat`
                          }}
                        ></div>
                      )}
                      <div className='flex flex-col items-center absolute bottom-1 justify-center w-full'>
                        <span className='text-16 md:text-20 font-black neonText'>{provider?.gpName}</span>
                        <span className='bg-primary rounded-full px-4 uppercase text-12 md:text-14'>
                          {provider?.gpNameEn}
                        </span>
                      </div>
                    </div>
                  </div>
                </button>
              )
            })}
            {listProviderData?.map((provider: ProviderDetailType, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => chooseGame(provider?.gCode ?? '')}
                  className='cursor-pointer overflow-hidden group duration-150'
                >
                  <div className='relative w-full overflow-hidden'>
                    <div className=' top-0 left-0 w-full'>
                      <img
                        src={provider?.gIconUrl}
                        alt={provider?.gName}
                        title={provider?.gName}
                        className='product__image w-full bg-black aspect-[37/22]'
                        width='220'
                        height='280'
                      />
                    </div>
                  </div>
                  <h3 className='product__title mt-3'>{provider?.gName}</h3>
                  {/* <div className='mt-3 rounded-md product__content bg-secondary-2 text-white text-12 flex flex-col justify-center items-center py-2 hover:bg-primary group-hover:text-content transition-all group-hover:bg-primary'>
                <p className='product__text'>{provider?.gCategory}</p>
              </div> */}
                </div>
              )
            })}
          </div>
        </>
      )}

      {Math.ceil(Number(dataProvider?.GameProviders?.total) / Number(dataProvider?.GameProviders?.per_page)) > 1 ||
      Math.ceil(Number(dataListProvider?.GameProvider?.total) / Number(dataListProvider?.GameProvider?.per_page)) >
        1 ? (
        <button onClick={loadMoreDataSlot} ref={buttonRefSlot}></button>
      ) : null}
      {/* {dataProvider && dataProvider?.GameProviders && dataProvider?.GameProviders?.data?.length > 0 ? (
        <Pagination
          className={`mx-auto block w-max pb-10 mt-0 pagination-casino`}
          pageSize={dataProvider?.GameProviders?.per_page ?? DEFAULT_LIMIT}
          onChange={(page) => {
            navigate(
              location.pathname +
                formatQueryParams({
                  ...queryParams,
                  offsetSlot: page,
                  limitSlot: dataProvider?.GameProviders?.per_page ?? DEFAULT_LIMIT
                })
            )
          }}
          current={Number(queryParams?.offsetSlot) || DEFAULT_OFFSET}
          total={dataProvider?.GameProviders?.total ?? 1}
        />
      ) : null} */}
      {/* {dataListProvider && dataListProvider?.GameProvider && dataListProvider?.GameProvider?.data?.length > 0 ? (
        <Pagination
          className={`mx-auto block w-max pb-10 mt-0 pagination-casino`}
          pageSize={dataListProvider?.GameProvider?.per_page ?? DEFAULT_LIMIT}
          onChange={(page) => {
            navigate(
              location.pathname +
                formatQueryParams({
                  ...queryParams,
                  offsetSlot: page,
                  limitSlot: dataListProvider?.GameProvider?.per_page ?? DEFAULT_LIMIT
                })
            )
          }}
          current={Number(queryParams?.offsetSlot) || DEFAULT_OFFSET}
          total={dataListProvider?.GameProvider?.total ?? 1}
        />
      ) : null} */}
      <Modal
        open={showGame}
        destroyOnClose
        centered
        className='!w-screen !h-screen max-w-none m-0 p-0 top-0 left-0'
        closeIcon={null}
        footer={null}
        classNames={{
          body: 'bg-black h-full w-full',
          content: '!bg-transparent w-full h-screen !p-2 md:!p-10',
          wrapper: 'w-screen h-screen'
        }}
      >
        <div className='w-full h-full relative'>
          {urlGame ? (
            <iframe
              src={urlGame}
              frameBorder='0'
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            loadingGameDetailSlot && (
              <Spin size='large' className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
            )
          )}
          <img
            className='absolute top-0 right-4 mt-[21px] z-10 cursor-pointer'
            src='/assets/images/icons/close-auth-modal.png'
            alt='close-modal'
            title='close'
            width='20'
            height='20'
            onClick={() => {
              setShowGame(false)
              dispatch(setIsPlayingGame(false))
              refetchGame()
            }}
          />
        </div>
      </Modal>
    </div>
  )
}
